export default [
    {
      path: '/admin',
      name: 'admin-dashboard',
      component: () => import('@/views/dashboard/streamlit/Streamlit.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Admin',
            active: true,
          },
        ],
      },
    },
  ]
  