export default [
    {
      path: '/instansi',
      name: 'instansi',
      component: () => import('@/views/warehouse/List.vue'),
      meta: {
        pageTitle: 'Instansi',
        breadcrumb: [
          {
            text: 'Instansi',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/instansi/product/:id',
      name: 'instansi-product-list',
      component: () => import('@/views/warehouse/product/List.vue'),
      meta: {
        pageTitle: 'Instansi',
        breadcrumb: [
          {
            text: 'Instansi',
          },
          {
            text: 'Products',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/instansi/product/:id',
      name: 'instansi-create-action',
      component: () => import('@/views/warehouse/product/Kalibrasi.vue'),
      props: true,
      meta: {
        pageTitle: 'Instansi',
        breadcrumb: [
          {
            text: 'Instansi',
          },
          {
            text: 'Products',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/instansi/:id_task/product',
      name: 'instansi-product-preview',
      component: () => import('@/views/warehouse/product/Preview.vue'),
      props: true,
      meta: {
        pageTitle: 'Instansi',
        breadcrumb: [
          {
            text: 'Instansi',
          },
          {
            text: 'Products',
          },
          {
            text: 'Export',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kalibrasi',
      name: 'kalibrasi',
      component: () => import('@/views/calibration/List.vue'),
      meta: {
        pageTitle: 'Kalibrasi',
        breadcrumb: [
          {
            text: 'Kalibrasi',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
  ] 
  