export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Analytics.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'Pengguna',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin',
    name: 'admin-dashboard',
    component: () => import('@/views/dashboard/streamlit/Streamlit.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'Admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/streamlit',
    name: 'apps-streamlit',
    component: () => import('@/views/dashboard/streamlit/Streamlit.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'Streamlit',
          active: true,
        },
      ],
    },
  },
]
