export default [
    {
      path: '/reporting/inventory',
      name: 'reporting-inventory',
      component: () => import('@/views/reporting/inventory/List.vue'),
      meta: {
        pageTitle: 'Reporting',
        breadcrumb: [
          {
            text: 'Inventory',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reporting/predict',
      name: 'reporting-predict',
      component: () => import('@/views/reporting/inventory/List.vue'),
      meta: {
        pageTitle: 'Reporting',
        breadcrumb: [
          {
            text: 'Predict',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
  ] 
  