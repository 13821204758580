export default [
  {
    path: '/setups/category',
    name: 'setups-category',
    component: () => import('@/views/setup/Categories.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Categories',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/category/create/:id',
    name: 'setups-category-create',
    component: () => import('@/views/setup/categories/CategoriesCreate.vue'),
    props: true,
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Categories',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/units',
    name: 'setups-unit',
    component: () => import('@/views/setup/Units.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Units',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/unit/action/:id',
    name: 'setups-unit-action',
    component: () => import('@/views/setup/unit/UnitAction.vue'),
    props: true,
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Units',
        },
        {
          text: 'Action',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/rule',
    name: 'setups-rule',
    component: () => import('@/views/setup/Rules.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Setups',
        },
        {
          text: 'Rules',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/account',
    name: 'setups-account',
    component: () => import('@/views/setup/Account.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Clients',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/account/create',
    name: 'setups-account-create',
    component: () => import('@/views/setup/account/AccountCreate.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Account',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/account/update/:id',
    name: 'setups-account-update',
    component: () => import('@/views/setup/account/AccountCreate.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Account',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/instansi',
    name: 'setups-instansi',
    component: () => import('@/views/setup/Instansi.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Instansi',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/instansi/create',
    name: 'setups-instansi-create',
    component: () => import('@/views/setup/instansi/InstansiCreate.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Instansi',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setups/label',
    name: 'setups-label',
    component: () => import('@/views/setup/Label.vue'),
    meta: {
      pageTitle: 'Setups',
      breadcrumb: [
        {
          text: 'Label',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
] 
