export default [
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/list.vue'),
    meta: {
      pageTitle: 'Inventaris',
      breadcrumb: [
        {
          text: 'Inventaris',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/snap/:label_qr_code', 
    name: 'apps-product-actions',
    component: () => import('@/views/product/scan/Apps.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/product/activity/:id',
    name: 'apps-product-activity',
    component: () => import('@/views/product/scan/Activity.vue'),
    meta: {
      layout: 'full'
    },
  },
  {
    path: '/product/activitys/:id',
    name: 'apps-product-activitys',
    component: () => import('@/views/product/scan/Activitys.vue'),
    meta: {
      layout: 'full'
    },
  },
  {
    path: '/product/transfer/:id',
    name: 'apps-product-transfer',
    component: () => import('@/views/product/scan/Activitys.vue'),
    meta: {
      layout: 'full'
    },
  },
  {
    path: '/product/details/:id',
    name: 'apps-product-details',
    component: () => import('@/views/product/view/ProductDetail.vue'),
    // component: () => import('@/views/components/nav/Nav.vue'),
    // component: () => import('@/views/components/timeline/Timeline.vue'),
    // component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    // component: () => import('@/views/pages/blog/BlogDetail.vue'),
    meta: {
      layout: 'full'
    },
  },
  {
    path: '/product/faq/:id',
    name: 'apps-product-faq',
    component: () => import('@/views/product/scan/Faq.vue'),
    meta: {
      layout: 'full'
    },
  },
  {
    path: '/product/guidance/:id',
    name: 'apps-product-guidance',
    component: () => import('@/views/product/scan/Guidance.vue'),
    meta: {
      layout: 'full'
    },
  },
  {
    path: '/product/create/:id',
    name: 'apps-product-create',
    component: () => import('@/views/product/create/ProductCreate.vue'),
    meta: {
      pageTitle: 'Inventaris',
      breadcrumb: [
        {
          text: 'Inventaris',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/view/:id',
    name: 'apps-product-view',
    component: () => import('@/views/product/create/ProductView.vue'),
    meta: {
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
] 
