export default [
  {
    path: '/operations/distribution',
    name: 'operations-distribution',
    component: () => import('@/views/operations/distribution/List.vue'),
    meta: {
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Distribution',
        },
        {
          text: 'Daftar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operations/activity',
    name: 'operations-activity',
    component: () => import('@/views/operations/activity/List.vue'),
    meta: {
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Activity',
        },
        {
          text: 'Daftar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operations/replenishment',
    name: 'operations-replenishment',
    component: () => import('@/views/operations/replenishment/List.vue'),
    meta: {
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Operations',
        },
        {
          text: 'Replenishment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operations/transaction',
    name: 'operations-transaction-create',
    component: () => import('@/views/operations/replenishment/Create.vue'),
    meta: {
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Operations',
        },
        {
          text: 'Transaction',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operations/supplying',
    name: 'operations-supplying',
    component: () => import('@/views/operations/supplying/List.vue'),
    meta: {
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Operations',
        },
        {
          text: 'Supplying',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operations/transfer',
    name: 'operations-transfer',
    component: () => import('@/views/operations/supplying/List.vue'),
    meta: {
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Operations',
        },
        {
          text: 'Transfer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operations/memo',
    name: 'operations-memo',
    component: () => import('@/views/operations/activity/List.vue'),
    meta: {
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Operations',
        },
        {
          text: 'Memo',
          active: true,
        },
      ],
    },
  },
] 
